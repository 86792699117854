<template>
  <div>
    <v-card class="pa-4">
      <v-row>
        <p class="text-2xl ma-5 mb-9">Edition groupée des objectifs</p>
        <v-spacer />
        <v-autocomplete
          outlined
          dense
          v-model="objectiveTypeSelected"
          :items="objectiveTypes"
          label="Type d'objectif"
          item-text="name"
          item-value="id"
          class="select-objective-type shrink my-5"
          @change="getData"
        ></v-autocomplete>
        <v-select
          outlined
          dense
          :items="years"
          v-model="yearSelected"
          label="Année"
          class="ma-5 shrink"
        ></v-select>
        <v-btn
          color="primary"
          class="mt-5 mr-5"
          @click.native="fillMissingMonths"
          >Remplir les mois manquants</v-btn
        >
      </v-row>
      <v-row class="mx-1 my-3">
        <v-data-table
          :headers="headers"
          :items="rows"
          item-value="id"
          class="elevation-1"
          :items-per-page="100"
          :footer-props="{
            'items-per-page-options': [20, 100, 500],
          }"
          dense
        >
          <template v-slot:body="{ items }">
            <tbody>
              <tr v-for="(row, _rowIndex) in items" :key="row.id">
                <td>{{ row.name }}</td>
                <td v-for="(month, monthIndex) in months" :key="monthIndex">
                  <v-text-field
                    v-model="row.data[month]"
                    single-line
                    type="number"
                    @change="
                      saveCell({
                        site_id: row.site_id,
                        name: row.name,
                        date: `${yearSelected}-${month}`,
                        value: row.data[month],
                      })
                    "
                    hide-details
                    :key="monthIndex"
                  ></v-text-field>
                </td>
              </tr>
            </tbody>
          </template>
        </v-data-table>
      </v-row>
      <v-row>
        <v-spacer />
      </v-row>
    </v-card>
    <v-snackbar v-model="successAlert" timeout="2000" top color="success">
      {{ successMessage }}
    </v-snackbar>
  </div>
</template>

<script>
import axios from "@axios";
import DateFilter from "@/components/common/filters/DateFilter";
import SiteFilter from "@/components/common/filters/SiteFilter";
import { saveRmraView } from "@/utils/rmr-analytics";

export default {
  name: "ObjectivesBulkEdit",
  components: {
    DateFilter,
    SiteFilter,
  },
  async created() {
    this.$store.commit("appConfig/UPDATE_APP_CONTENT_WIDTH", "fullWidth");
    const currentYear = new Date().getFullYear();
    this.years = [
      currentYear - 2,
      currentYear - 1,
      currentYear,
      currentYear + 1,
      currentYear + 2,
    ];
    this.yearSelected = currentYear;
    const { data } = await axios.get("/objectives/types/");
    this.objectiveTypes = data;
    this.objectiveTypeSelected =
      this.objectiveTypes.find((item) => item.name === "CA")?.id ?? 0;

    await this.getData();
  },
  beforeDestroy() {
    this.$store.commit("appConfig/UPDATE_APP_CONTENT_WIDTH", "boxed");
  },
  data() {
    return {
      successAlert: false,
      successMessage: "",
      objectiveTypeSelected: 0,
      objectiveTypes: [],
      headers: [
        { text: "Name", value: "name" },
        { text: "Janvier", value: "01" },
        { text: "Février", value: "02" },
        { text: "Mars", value: "03" },
        { text: "Avril", value: "04" },
        { text: "Mai", value: "05" },
        { text: "Juin", value: "06" },
        { text: "Juillet", value: "07" },
        { text: "Aout", value: "08" },
        { text: "Septembre", value: "09" },
        { text: "Octobre", value: "10" },
        { text: "Novembre", value: "11" },
        { text: "Décembre", value: "12" },
      ],
      years: [],
      yearSelected: null,
      months: [
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
      ],
      rows: [],
    };
  },
  mounted() {
    saveRmraView(this.$route);
  },
  methods: {
    async fillMissingMonths() {
      const params = {
        year: this.yearSelected,
        obj_type: this.objectiveTypeSelected,
      };
      const { status } = await axios.post(
        `/objectives/fill-missing-months/`,
        {},
        {
          params,
        }
      );
      if (status === 200) {
        this.successAlert = true;
        this.successMessage = `Remplissage terminé, rechargez la page`;
      }
    },
    async getData() {
      const params = {
        year: this.yearSelected,
        type: this.objectiveTypeSelected,
      };
      const {
        data: { items },
      } = await axios.get(`/objectives/grouped-yearly/`, {
        params,
      });
      this.rows = this.formatData(items);
    },
    formatData(items) {
      const formattedData = {};
      for (const item of items) {
        const { date, site_name, site_id, value } = item;
        const month = date.split("-")[1];
        const name = `${site_name}///${site_id}`;
        if (!(name in formattedData)) {
          formattedData[name] = {};
        }
        formattedData[name][month] = value;
      }
      const rows = [];
      for (const [key, datum] of Object.entries(formattedData)) {
        const [name, site_id] = key.split("///");
        const row = { name, site_id, data: {} };
        row.data = datum;
        rows.push(row);
      }
      return rows;
    },
    async saveCell(item) {
      const { status } = await axios.post(
        "/objectives/",
        {},
        {
          params: {
            sites: item.site_id,
            date: item.date,
            objtype: this.objectiveTypeSelected,
            value: item.value,
          },
        }
      );
      if (status === 200) {
        this.successAlert = true;
        this.successMessage = `${item.name} ${item.date} enregistré`;
      }
    },
  },
};
</script>
